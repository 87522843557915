html,
body,
#root {
  height: 100%;
}
#root {
  display: flex;
  flex-flow: column;
}
body {
  margin: 0;
  background-color: #eee;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Cantarell",
    "Helvetica Neue", "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* COLORS */
:root {
  --clr-blue: 210, 79%, 46%;
  --clr-green: 123, 43%, 39%;
  --clr-red: 354, 79%, 46%;
  --clr-yellow: 53, 84%, 60%;
  --clr-gray: 0, 0%, 58%;
  --clr-lightgray: 0, 0%, 44%;

  --clr-blue-h: 210, 79%, 56%;
  --clr-green-h: 123, 43%, 49%;
  --clr-red-h: 354, 79%, 56%;
  --clr-yellow-h: 55, 100%, 74%;
  --clr-gray-h: 0, 0%, 68%;
  --clr-lightgray-h: 0, 0%, 54%;
}
/* COLORS */
/* BACKGROUNDS */
.main {
  flex: 1 1 auto;
  text-align: center;
  padding: 2rem 0;
}
.main-ranking,
.main-fetcher,
.main-about,
.main-changelog {
  background-image: url(https://osu.ppy.sh/images/backgrounds/button.svg);
  background-size: cover;
  padding-top: 0;
}
.main-ranking {
  background-color: hsl(114, 38%, 85%);
}
.main-fetcher {
  background-color: hsl(60, 38%, 85%);
}
.main-home {
  background-color: hsl(202, 16%, 86%);
}
.main-about {
  background-color: hsl(209, 38%, 85%);
}
/* BACKGROUNDS */
/* CONTAINERS */
.container-main {
  padding: 0 16%;
}
.container-center {
  max-width: 72ch;
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.wrapper {
  position: relative;
  overflow: auto;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.home-buttons {
  padding: 0 3%;
  overflow-x: clip;
  display: flex;
  flex-wrap: wrap;
}
/* CONTAINERS */

hr {
  margin: 1rem 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}

/* INPUTS */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input,
select,
textarea {
  margin-bottom: 0;
  padding: 4px;
  border-radius: 0.25rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}
:is(input, select, textarea):focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(0, 123, 255, 0.25);
}
textarea {
  max-height: 40%;
}
/* INPUTS */
/* BUTTONS */
.btn,
button {
  display: inline-flex;
  --clr: var(--clr-gray);
  color: #111;
  text-decoration: none;
  font-size: 1rem;
  border: 1px solid #111;
  border-radius: 0.4rem;
  background-color: hsl(var(--clr));
  border-color: hsl(var(--clr));
  padding: 0.375rem 0.75rem;
  margin: 0;
  align-items: center;
  user-select: none;
  transition: background-color 0.2s, border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}
.btn:not([disabled], .disabled):hover,
button:not([disabled], .disabled):hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.15);
}
:is(.btn, button):focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem hsla(var(--clr), 0.5);
}
:is(.btn, button) img {
  filter: invert(1);
}
.btn[disabled],
.btn.disabled {
  opacity: 0.65;
}

.btn-blue {
  --clr: var(--clr-blue);
  color: #eee;
}
.btn-blue:not([disabled]):hover {
  background-color: hsl(var(--clr-blue-h));
}
.btn-green {
  --clr: var(--clr-green);
  color: #eee;
}
.btn-green:not([disabled]):hover {
  background-color: hsl(var(--clr-green-h));
}
.btn-gray {
  --clr: var(--clr-gray);
  color: #eee;
}
.btn-gray:not([disabled]):hover {
  background-color: hsl(var(--clr-gray-h));
}
.btn-red {
  --clr: var(--clr-red);
  color: #eee;
}
.btn-red:not([disabled]):hover {
  background-color: hsl(var(--clr-red-h));
}
.btn-yellow {
  --clr: var(--clr-yellow);
  color: #111;
}
.btn-yellow:not([disabled]):hover {
  background-color: hsl(var(--clr-yellow-h));
}
/* card buttons */
.btn-card-main {
  box-shadow: 0 0 1.4vw 0 #aaa;
  background-color: #eee;
  min-width: 256px;
  flex: 1;
  margin: 1rem 1rem;
  transition: transform 0.3s;
}
.btn-card-main a {
  text-decoration: none;
  color: #111;
  font-weight: 700;
  font-size: 1.1rem;
  transition: color 0.3s;
}
.btn-card {
  padding: 100px 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}
.btn-card-label {
  padding: 0.65rem 3%;
  text-align: right;
}

.btn-card-main:hover {
  transform: translateY(-9px);
}
.btn-card-main:hover a {
  color: #666;
}
/* BUTTONS */

.gradient-text {
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

label {
  padding-right: 8px;
}

/* GROUPS */
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(27, 94, 32, 0.2);
  border-radius: 0.4rem;
  z-index: 1;
}
.toolbar > :first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.toolbar > :last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.group {
  display: flex;
  overflow: hidden;
  padding: 0.15rem;
  margin: -0.15rem;
}
.group > :is(input, button):not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.group > :is(input, button):not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
/* GROUPS */

/* LOADER */
.loader {
  --loader-size: 4.2rem;
  --loader-thickness: 0.78rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
}
.loader-sticky {
  position: sticky;
  top: 50%;
  margin-top: calc(var(--loader-size) * (-1) + var(--loader-thickness) * (-2));
}
.loader::after {
  content: "";
  width: var(--loader-size);
  height: var(--loader-size);
  border: var(--loader-thickness) solid rgba(0, 0, 0, 0.1);
  border-top-color: #eee;
  border-radius: 50%;
  animation: loader 0.82s linear infinite;
}
@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* LOADER */

/* ALERTS */
.alert-wrap {
  display: flex;
  justify-content: center;
  margin: 1rem 0.2rem;
}
.alert {
  background-color: hsl(0, 100%, 100%);
  padding: 1.1rem 1rem;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  box-shadow: 0.4rem 0.4rem 0.5rem rgba(0, 0, 0, 0.22);
  white-space: pre-line;
}
.alert-accent {
  border-top-left-radius: 0.45rem;
  border-bottom-left-radius: 0.45rem;
  padding-left: 1rem;
}
.alert > h2 {
  margin-top: 0;
}
/* ALERTS */

/* MAIN LAYOUT */
/* header */
header {
  width: 100%;
  padding-top: 1.25%;
  padding-bottom: 1.75%;
  flex: 0 0 auto;
  background-color: rgb(220, 200, 240);
  background-image: url("../src/layouts/img/home-red-brown.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 63%;
  text-align: center;
  color: #fff;
  font-weight: 750;
  font-size: 3.5rem;
  user-select: none;
  overflow: hidden;
}
header :first-child {
  text-decoration: none;
  color: #fff;
  transition: color 0.35s, text-shadow 0.35s;
  text-shadow: 0.33rem 0.33rem 0.3rem #222;
}
header :first-child:hover {
  background: linear-gradient(
    125deg,
    #ff2400,
    #e81d1d,
    #e8b71d,
    #e3e81d,
    #1de840,
    #1ddde8,
    #2b1de8,
    #dd00f3,
    #dd00f3,
    #2b1de8,
    #1ddde8,
    #1de840,
    #e3e81d,
    #e8b71d,
    #e81d1d,
    #ff2400
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0.33rem 0.33rem 0rem #fff;
  animation: rainbo 9s linear infinite alternate;
  background-size: 300% 100%;
  text-decoration: none;
}

@keyframes rainbo {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
/* header */
/* navbar */
.navbar {
  display: flex;
  background-color: #eee;
  user-select: none;
}
.navbar-secondary {
  background: linear-gradient(180deg, #eee, transparent);
  text-align: center;
  position: relative;
  --w: 0;
  --x: 0;
}
.navbar-secondary::after {
  content: "";
  position: absolute;
  bottom: 2px;
  width: var(--w);
  height: 2px;
  background-color: #fff;
  transform: translateX(var(--x));
  transition: 0.3s transform, 0.3s width;
}

.tab {
  margin: 0.45% 1.1%;
  color: #111;
  font-size: 1.125rem;
  font-weight: 300;
  cursor: pointer;
  transition: color 0.25s;
}
.navbar:not(.navbar-secondary) > .tab-active {
  text-shadow: 0 1px 1px rgb(101, 52, 156);
}
.tab:hover {
  color: #42a1ee;
}
.tab-right {
  margin-left: auto;
}
.navbar-secondary .tab {
  padding: 0;
  margin: 0.35% 0.9%;
}
.navbar:not(.navbar-secondary) .tab::before {
  content: ">";
  font-weight: 700;
  margin-right: 0.25rem;
  text-shadow: none;
  color: #111;
  display: none;
}

.navbar a {
  text-decoration: none;
}
/* navbar */
/* footer */
footer {
  width: 100%;
  padding: 1.1% 0;
  background-color: hsl(209, 20%, 68%);
  text-align: center;
  color: #111;
  font-size: 1rem;
  position: relative;
  bottom: 0;
  line-height: 0.9;
}
footer a {
  text-decoration: none;
  color: #111;
}
footer a:hover {
  text-decoration: underline;
}
/* footer */
/* MAIN LAYOUT */

/* TOOLTIP */
.tooltip::before,
.tooltip::after {
  --scale: 0;
  --arrow-size: 0.6875rem;
  --tooltip-color: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: -0.25rem;
  left: 50%;
  font-weight: 400;
  transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
  transition: 220ms transform;
  transform-origin: bottom center;
}

.tooltip::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.3rem;
  text-align: center;
  width: max-content;
  max-width: 110%;
  background: var(--tooltip-color);
}
.tooltip::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

.tooltip:hover::before,
.tooltip:hover::after {
  --scale: 1;
}
/* TOOLTIP */

/* ICONS */
.icon {
  height: 1.25rem;
  width: 1.25rem;
  background-size: cover;
  filter: invert(1);
}
.icon-big {
  height: 3.2rem;
  width: 3.2rem;
}
.icon-hoverable {
  opacity: 0.6;
  cursor: pointer;
  transition: opacity 0.2s ease;
}
.icon-hoverable:hover {
  opacity: 1;
}

.icon-add {
  background-image: url("../src/layouts/img/icon_add.svg");
}
.icon-search {
  background-image: url("../src/layouts/img/icon_search.svg");
}
.icon-expand {
  background-image: url("../src/layouts/img/icon_expand.svg");
  filter: none;
}
/* ICONS */
/* AUTOCOMPLETE */
.autocmp-wrapper {
  position: relative;
  display: inline-flex;
}
.autocmp {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  top: 100%;
  left: 0;
  right: 0;
  cursor: pointer;
  background-color: #fff;
}
.autocmp-cntry {
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: left;
}
.autocmp-cntry:hover,
.autocmp-cntry:focus {
  background-color: #35a4ff;
  outline: none;
}
.autocmp-inside {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
}
.autocmp-inside:hover,
.autocmp-inside:focus {
  background-color: rgba(255, 255, 255, 0.4);
  outline: none;
}
.autocmp-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0 3%;
  z-index: 2;
}
/* AUTOCOMPLETE */

.twitter {
  color: #1361c2;
}
.twitter:hover {
  color: #093d7d;
  text-decoration: underline;
}
.discord {
  color: #676e78;
}

.ReactCollapse--collapse {
  transition: height 260ms;
}
.ReactCollapse--collapse input,
select,
fieldset {
  margin-bottom: 0.42rem;
  padding-bottom: 0.3125rem;
}
.ReactCollapse--content-ranking {
  padding: 0 20%;
}
.ReactCollapse--content {
  padding-top: 1px;
  padding-bottom: 3px;
  overflow: hidden;
}
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.22);
  border-radius: 0.25rem;
}

.date-em {
  text-decoration: underline #555;
}
.nowrap {
  white-space: nowrap;
}
small {
  font-size: 75%;
  opacity: 0.9;
}

/* RANKING CHART */
.chart-wrapper {
  background-color: hsla(0, 0%, 100%, 0.38);
  height: 410px;
}
.chart-stats-tooltip {
  background-color: hsla(0, 0%, 100%, 0.75);
  border: 1px solid #000;
  border-radius: 0.5rem;
  padding: 0.35rem 1rem;
}
.recharts-tooltip-wrapper {
  z-index: 2;
}

.chart-player {
  display: flex;
  align-items: center;
  background-color: hsla(0, 0%, 95%, 0.82);
  border: 2px solid hsl(0, 0%, 85%);
  margin: 0 20%;
  padding: 0.3rem 0.4rem;
}
.chart-player-wrapper .ReactCollapse--collapse {
  background-color: hsla(0, 0%, 95%, 0.82);
  margin: 0 20% 0.3rem 20%;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}
.chart-player .icon {
  height: 1.8rem;
  width: 1.8rem;
  transition: transform 0.26s;
  cursor: pointer;
}
.chart-player .icon:hover {
  filter: invert(0.7);
}
.chart-player a {
  color: #111;
  text-decoration: none;
}
.chart-player a:hover {
  text-decoration: underline;
}

.chart-player-ico-enabled,
.chart-player-ico-disabled {
  cursor: pointer;
  font-weight: 800;
  font-size: 1.2rem;
  user-select: none;
  margin-right: 1rem;
  transition: opacity 0.25s ease;
}
.chart-player-ico-disabled {
  opacity: 0.3;
}
.chart-player-ico-disabled:hover {
  opacity: 0.6;
}
.chart-player-ico-enabled:hover {
  opacity: 0.7;
}
.chart-player-50 {
  background-image: linear-gradient(45deg, #0d47a1, #039be5);
}
.chart-player-r {
  background-image: linear-gradient(45deg, #e65100, #ffb300);
}

.chart-settings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 6px 10px;
  overflow-x: clip;
}
.chart-settings input {
  margin-left: 0.3rem;
}
/* RANKING CHART */
.ranking-header {
  font-weight: 300;
}

/* MISC RANKING */
.misc-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4px;
}
.misc-stat {
  display: flex;
  flex-direction: column;
  width: 47%;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.2rem;
  transition: 0.2s background-color ease;
}
.misc-stat:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.misc-stat > h3 {
  font-weight: 300;
  font-size: 0.875rem;
  margin: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  width: fit-content;
  text-align: left;
}
.misc-stat-value {
  margin: 1rem 0;
  font-weight: 500;
}
/* MISC RANKING */

/* SENKO */
.senko-body {
  user-select: none;
  -webkit-user-drag: none;
  text-align: center;
  font-family: Calibri, Roboto, sans-serif;
  font-size: 1rem;
}
.senko-body a {
  text-decoration: none;
  color: #666;
}
.senko-body a:hover {
  text-decoration: underline;
}

#senko > img {
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 2vw 0 #aaa;
  transition: box-shadow 0.16s;
  transition-timing-function: linear;
  margin-top: 3vh;
  margin-bottom: 17vh;
  -webkit-user-drag: none;
  height: 26%;
}
#senko {
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.txt {
  transition: opacity 0.7s;
}
.counter {
  transition: opacity 1.2s;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  z-index: 1;
  text-align: center;
}
/* SENKO */

/* INLINE */
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 1rem;
}
.mr-4 {
  margin-right: 1.5rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 1rem;
}

.mr-rel-1 {
  margin-right: 0.75%;
}
.mr-rel-2 {
  margin-right: 1.25%;
}
.mr-rel-3 {
  margin-right: 2.25%;
}

.ml-rel-1 {
  margin-left: 0.75%;
}
.ml-rel-2 {
  margin-left: 1.25%;
}
.ml-rel-3 {
  margin-left: 2.25%;
}
/* INLINE */

/* QUERIES */
@media screen and (min-width: 1366px) {
  .container-main {
    padding: 0 22.5%;
  }
}
@media screen and (max-width: 640px) {
  .container-main {
    padding: 0 0;
  }

  .navbar {
    flex-flow: column;
  }
  .navbar-secondary::after {
    display: none;
  }
  .navbar:not(.navbar-secondary) .tab::before {
    display: inline;
  }
  .navbar-secondary .tab-active {
    text-shadow: 0 1px 1px rgb(101, 52, 156);
  }
  .tab-right {
    margin-left: 1.1%;
  }

  .chart-player-wrapper .ReactCollapse--collapse {
    margin: 0 0 0.25rem 0;
  }
  .chart-player {
    margin: 0;
  }
}
/* QUERIES */
