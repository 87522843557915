.main-about {
  background-color: hsl(209, 38%, 85%);
  color: #000;
  text-align: left;
}

.site-link {
  color: #444;
}
.player-link {
  color: #587d4b;
}
.main-about h1 {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 0.45vh;
  border-radius: 0.52vh;
  margin: 1.75vh 0;
  text-align: center;
}
.main-about .answer {
  max-width: 80ch;
  line-height: 1.4;
}
