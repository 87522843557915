.toolbar-date {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.toolbar-date .datepicker {
  width: calc(100px + 8vw);
}
.datepicker {
  cursor: text;
}

@media screen and (max-width: 640px) {
  .toolbar-date > .btn-green {
    border-radius: 0;
  }
}
