.ranking-entry {
  border: 2px solid #000;
  border-radius: 0.125rem;
  font-size: 1rem;
  background-color: rgba(100, 100, 100, 0.15);
  transition: background-color 0.25s;
  margin-top: 3px;
  padding: 0.1rem 0;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}
.ranking-entry:hover,
.ranking-entry:focus {
  background-color: rgba(120, 160, 255, 0.32);
}

#r1 {
  --bg-clr: 236, 188, 43;
  margin-top: 0;
}
#r2 {
  --bg-clr: 190, 195, 230;
}
#r3 {
  --bg-clr: 151, 104, 65;
}
:is(#r1, #r2, #r3) {
  background-color: rgba(var(--bg-clr), 0.35);
}
:is(#r1, #r2, #r3):hover {
  background-color: rgba(120, 160, 255, 0.32);
}

.ranking-entry a {
  display: flex;
  color: #111;
  text-decoration: none;
}
.ranking-entry a:hover {
  text-decoration: underline;
}

.ranking-entry-username {
  display: flex;
  align-items: center;
  min-width: 16.25ch;
  overflow: hidden;
}
.ranking-entry-gain-before-after {
  margin-left: 1.25rem;
}

.ranking-dots {
  max-width: 1ch;
  display: block;
  margin-bottom: 2.2rem;
  margin-left: 47%;
  font-size: 3rem;
  line-height: 0.7;
  user-select: none;
}
.ranking-dots ~ .ranking-dots {
  display: none;
}

.small-img {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.28);
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
}
.avatar {
  height: 2rem;
  width: 2rem;
}
.flag {
  height: 1.3125rem;
}

/* ARROWS */
/* arrow up by default, 18x12 px */
.arrow {
  width: 0;
  height: 0;
  padding: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 11px solid hsl(110, 60%, 45%);
}
.arrow-down {
  transform: rotate(180deg);
  border-bottom: 11px solid hsl(0, 60%, 50%);
}
.line {
  width: 18px;
  height: 3px;
  background-color: hsl(0, 0%, 20%);
}
.circle {
  width: 11px;
  height: 11px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: hsl(207, 60%, 50%);
}
/* ARROWS */

/* MEDIA */
@media screen and (max-width: 410px) {
  .ranking-entry :is(img, .rank-country, .small-img) {
    display: none;
  }
  .ranking-entry {
    border: none;
    margin-top: 2px;
  }
  .ranking-dots {
    line-height: 0.25;
  }
  .ranking-entry-username {
    min-width: 0;
    margin-right: 1rem;
  }
  .ranking-entry-gain-before-after {
    display: none;
  }
}
