.container-404 {
  background-color: #7eb2dd;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  color: #111;
}

.container-404 > a {
  color: #ddd;
}
