.table-country {
  width: 100%;
  border-spacing: 0;
  overflow: hidden;
}
.table-country tr:nth-child(even) {
  background-color: rgba(50, 50, 50, 0.11);
}
.table-background {
  background-color: rgba(27, 94, 32, 0.2);
}

.table-country td {
  padding: 6px 0;
}

.table-country th.sortable:hover {
  cursor: pointer;
  background-color: rgba(27, 94, 32, 0.33);
  user-select: none;
}
.table-country .desc::after,
.asc::after {
  display: inline-block;
  content: "";
  margin-left: 0.25rem;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 12px solid hsla(0, 0%, 20%, 0.7);
}
.table-country .desc::after {
  transform: rotate(180deg);
}

/* MEDIA */
@media screen and (max-width: 640px) {
  .table-country .country-name {
    display: none;
  }
  .table-country td {
    padding: 1px 0;
  }
}
