.main-changelog {
  background-image: none;
  background-color: hsl(32, 39%, 79%);
  color: #333;
  text-align: left;
}
.main-changelog h2 {
  text-align: center;
  padding-bottom: 0.4rem;
}
.main-changelog ul {
  color: #111;
  line-height: 1.5;
}
.main-changelog li {
  padding-bottom: 0.86%;
}
.main-changelog .bottom-info {
  font-size: 80%;
  text-align: center;
}

.change-entry-head {
  display: flex;
  background-color: hsla(0, 0%, 20%, 0.15);
  align-items: center;
  font-size: 1.75rem;
  border-radius: 0.34rem;
  padding: 0.6rem 0.25rem;
  margin-top: 0.8rem;
  cursor: pointer;
}
.change-entry-head .icon {
  height: 2rem;
  width: 2rem;
  transition: transform 0.26s;
}
.change-entry-head .icon:hover {
  filter: invert(1);
}

@media (max-width: 640px) {
  .main-changelog .container-center {
    margin-left: 0;
    transform: none;
  }
}
